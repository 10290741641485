<template>
  <router-view />
</template>

<i18n>
{
  "nl": {
    "title": "Sporten bij de Raad van Beheer"
  },
  "en": {
    "title": "Sports at Raad van Beheer"
  }
}
</i18n>
<script>

import { useI18n } from 'vue-i18n';
import { provide } from 'vue';
import { useHead } from '@unhead/vue';

export default {
  name: 'Application',
  setup() {
    const { t } = useI18n();

    provide('windowed', false);

    useHead({
      titleTemplate: (title) => {
        if (title === undefined) {
          // Do not update if we do not have a title set
          return null;
        }
        return ((title) ? `${title} – ${t('title')}` : t('title'));
      },
    });

    return {
      t,
    };
  },
};
</script>
